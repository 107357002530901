if (window.location.href.indexOf('loginDone=true') >= 0) {

  var url = window.location.href.split('?');
  var params = url[1].replace('loginDone=true', '');

  if (!!params) {
    var moreParams = params.split('&');
    var finalParams = '';
    for (var i = 0; i < moreParams.length; i++) {
      if (moreParams[i] != '') {
        finalParams = moreParams[i] + '&';
      }

    }

    if (finalParams.length > 0) {
      finalParams = finalParams.substring(0, finalParams.length - 1);
    }

    if (finalParams != '') {
      params = '?' + finalParams;
    } else {
      paramas = '';
    }
    //console.log(params);
  }

  window.history.replaceState({}, document.title, url[0] +  '/login_erfolgreich' + params );

} else if (window.location.href.indexOf('/login_start') >= 0) {

  if (document.getElementsByClassName('list-group-item-danger').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('login_start', 'login_fehler'));
  }

} else if (window.location.href.indexOf('/reg_start') >= 0) {

  if (document.getElementsByClassName('reg-error').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('reg_start', 'reg_fehler'));
  } else if (document.getElementsByClassName('reg-success').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('reg_start', 'reg_erfolgreich'));
  }

}  else if (window.location.href.indexOf('/password-reset') >= 0) {

  if (document.getElementsByClassName('reset-error').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('password-reset', 'password-reset_fehler'));
  } else if (document.getElementsByClassName('reset-sent').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('password-reset', 'password-linksent'));
  } else if (document.getElementsByClassName('reset-done').length > 0) {
    window.history.replaceState({}, document.title, window.location.href.replace('password-reset', 'password-reset_done'));
  }

}