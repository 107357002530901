var timestamp = (new Date()).getTime();
var loadingPage = false;
$(document).ready(function() {

    $(window).on('scroll', function() {
        if (!loadingPage && $(window).scrollTop() >= ($(document).height() - $(window).height() - $('footer').height() + 50) && $('.stage-articles .loadMore').length > 0) {
            loadingPage = true;
            $('.stage-articles .loadMore .button').addClass('loading');
            $('.stage-articles .loadMore .button i').addClass('fa-spin');
            $('.stage-articles a.loadMoreLink').click();
        }
    });

    $(document).on('click', '.registration form.form-register-newsletters button.submitNewsletterOptionsFormTrigger', function(e){
        if ($('.newsletters_page .csPreloader').length > 0) {
            $('.newsletters_page .csPreloader').toggle();
        }
        var values = "";
        $('.registration form.form-register-newsletters input[type="checkbox"]:checked').each(function() {
            values += $(this).val() + ",";
        });
        $('.registration form.form-register-newsletters input.registration_newsletteroptions_hidden').val(values);
        $('.registration form.form-register-newsletters input[type="submit"].newsletteroptions_btn').click();
    });

    $(document).on('click', 'a[href*="showCookieTool"]', function (event) {
        event.stopPropagation();
        event.preventDefault();
        // showCookieTool(event.originalEvent);
    UC_UI.showFirstLayer();
    });

    //MAIN PAGE ADS
    refreshAds('.home.stage-articles', 12);
    refreshAds('.editorial-articles.most_read', 3);
    checkSliderAds();

    //CATEGORIES ADS
    refreshCategoriesAds();

    $(document).on('click', '.ui-datepicker-year', function() {
        //$('.ui-datepicker-title select').show();
        console.log('calendar');
    });

    $(document).on('click', '.articleMediaButtons .socialTrigger', function() {
        $('.articleMediaButtons .socialIcons').toggle();
    });

    /* Special Banner in Home Popular Articles */

    $("#specialBanner").mouseover(function() {
        $("#customImageText").css("display", "block");
    });
    $("#specialBanner").mouseout(function() {
        $("#customImageText").css("display", "none");
    });


    $('#bannerLink').click(function(event) { trackOutboundLink(event, 'https://players.brightcove.net/4309680634001/default_default/index.html?videoId=6085272476001'); });
    /*
    $(document).on("mouseover", '#specialBanner', function () {
      document.getElementById("customImageText").style.display = "block";
    });*/



    if ($(".html-string").length > 0) {
        var log = $(".html-string");
        var str = $("div.html-string").text();

        var html = $.parseHTML(str);
        log.append(html);

        $(".html-string").contents().filter(function() {
            return (this.nodeType == 3);
        }).remove();
    }

    // ePaper / Archiv Page
    $(document).on('change', '.epapers_list form select.epaper-datefilter-select', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('.epapers_list form input.epaper-datefilter-value').val($(this).val());
        $('.epapers_list form input.epapers_list_btn').click();
    });

    checkNewsletter();

    $(document).on('change', 'form.newsletter-feed select', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('form.newsletter-feed input.udpateFeed').click();
    });


    


});





/********************************
 *
 *  DATA LAYER GTM
 *
 ********************************/

function checkDatalayer(){
        if ($("#userdata").length > 0) {
            lgoinInfo = 'true';
            beruf = $("#userdata").attr('data-beruf');
            
            if(beruf == 'arzt'){
                fachgebiete = $("#userdata").attr('data-fachgebiet');
                var fArray = fachgebiete.split(',');
                if(fArray[0] != null){
                    F1 = fArray[0].toString();
                }
                if(fArray[1] != null){
                    F2 = fArray[1];
                }
                if(fArray[2] != null){
                    F3 = fArray[2];
                }

            }else{
                F1 = beruf
            }


        }else{
            lgoinInfo = 'false';
            F1 = 'sonstiges';
        }


        window.dataLayer = window.dataLayer || [];
            if(F2 != null && F3 == null){
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'F2': F2,
                    'articleDate': articleDate
                }];
            } else if(F3 != null){
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'F2': F2,
                    'F3':F3,
                    'articleDate': articleDate
                }];
            }else{
                dataLayer = [{
                    'login-info': lgoinInfo,
                    'F1': F1,
                    'articleDate': articleDate
                }];
            }

    }



/********************************
 *
 *  COMMON FUNCTIONS
 *
 ********************************/

function getAdScript(adId) {
    var loggedUser = isUserLogged();
    let script = '<ins class="asm_async_creative" style="display:inline-block; width:160px; height:600px;text-align:left; text-decoration:none;" data-asm-cdn="cdn.adspirit.de" data-asm-host="universimed.adspirit.de" data-asm-params="pid=' + adId + '&ord=' + timestamp + '&isLoggedIn=' + loggedUser + '"></ins>';
    script += '<script src="https://cdn.adspirit.de/adasync.min.js" async type="text/javascript"></script>';

    return script;
}

function isUserLogged() {
    var loggedUser = '0';
    if ($('.hidden.chekuser.logged').length > 0) {
        loggedUser = '1';
    }
    return loggedUser;
}

function getCssLink() {

    var iframeCss = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port.length > 0) {
        iframeCss += ":" + window.location.port;
    }
    iframeCss += '/custom/css/ads_iframes.css';

    var cssLink = document.createElement("link");
    cssLink.href = iframeCss
    cssLink.rel = "stylesheet";
    cssLink.type = "text/css";
    return cssLink;

}

/********************************
 *
 *  CATEGORIES
 *
 ********************************/
function refreshCategoriesAds() {
    var currentPage = +$('.stage-articles .status.currentPage').html();
    var pageSize = +$('.stage-articles .status.pageSize').html();
    var mainContainer = '.categories.stage-articles';
    refreshAds(mainContainer, currentPage * pageSize);
}

function refreshAds(mainContainer, limit) {
    var selector = '.add_to_check';
    var loggedUser = isUserLogged();
    var iframes = $(mainContainer + ' .article-list ' + selector + '.hidden div[needLoad]');
    if (iframes.length > 0) {
        loadIFrame(mainContainer, selector, false, iframes, 0, loggedUser, limit);
    }
}

function addLongBanners(mainContainer) {
    var loggedUser = isUserLogged();
    var selector = '.add_long';

    var iframes = $(mainContainer + ' .article-list ' + selector + '.hidden div[needLoad]');
    if (iframes.length > 0) {
        loadIFrame(mainContainer, selector, true, iframes, 0, loggedUser, 0);
    }
}

function loadIFrame(mainContainer, selector, long, iframes, index, loggedUser, limit) {

    var iframe = $('<iframe />', {
        name: 'adIframe',
        srcdoc: '<script src="' + $(iframes[index]).attr('src').replace('[timestamp]', timestamp).replace('[isLoggedIn]', loggedUser) + '"></script>',
        class: "iframeAds"
    });

    var position = $(iframes[index]).data('article-position');
    var articles = $(mainContainer + ' .article-list .article');
    if (articles.length > position) {
        var article = articles[position];
        var addContainer = createAddContainer(article, iframe, long);

        $(iframe).load(function() {

            if ($(iframe).contents().text().indexOf('window.asm_obaid') == -1) {
                $(iframe).parents(selector + '.hidden').remove();
                $(addContainer).remove();
            } else {
                $(iframe).contents().find('head').append(getCssLink());
                if ($(addContainer).hasClass('add_long')) {
                    $(addContainer).removeClass('hidden');
                }
            }

            if (iframes[index + 1]) {
                loadIFrame(mainContainer, selector, long, iframes, index + 1, loggedUser, limit);
            } else if (!long) {
                refreshColumns(mainContainer, limit);
            }

        });
    } else if (!long) {
        refreshColumns(mainContainer, limit);
    }
}

function refreshColumns(mainContainer, limit) {

    $(mainContainer + ' .article-list .clearfix').remove();
    $(mainContainer + ' .article-list .article').each(function(index) {

        $(this).attr('data-article-index', index);
        $(this).removeClass('no-border');

        if ($(this).hasClass('add')) {
            $(this).removeClass('hidden');
        }

        if (index % 3 == 0) {
            $(this).addClass('no-border');
        }
        if (index % 3 == 2) {
            $(this).addClass('add-clearfix');
        }

        if (limit > 0 && index >= limit) {
            $(this).remove();
        }

    });

    $('<div class="clearfix"></div>').insertAfter(mainContainer + ' .article-list .article.add-clearfix');

    addLongBanners(mainContainer);
}

function createAddContainer(articlePosition, iframe, long) {
    var addContainer = document.createElement("div");
    if (!long) {
        $(addContainer).addClass('col-xs-12 col-sm-12 col-md-4 add article hidden');
        $(addContainer).insertBefore(articlePosition);
    } else {
        $(addContainer).addClass('col-xs-12 add_long hidden');
        $(addContainer).insertAfter(articlePosition);
    }


    var addDiv = document.createElement("div");
    $(addDiv).addClass('adContainer');
    if (long) $(addDiv).addClass('long_banner');
    $(addDiv).appendTo(addContainer);
    iframe.appendTo(addDiv);

    return addContainer;
}

/********************************
 *
 *  SLIDER
 *
 ********************************/
function checkSliderAds() {
    $('.stage-articles.home .carousel.slide .carousel-item.ad div[needLoad]').each(function() {
        var parentContainer = $(this).parents('.carousel-item.ad');
        var loggedUser = isUserLogged();

        var iframe = $('<iframe />', {
            name: 'adIframe',
            srcdoc: '<script src="' + $(this).attr('src').replace('[timestamp]', timestamp).replace('[isLoggedIn]', loggedUser) + '"></script>',
            class: "iframeAds"
        });
        $(iframe).load(function() {
            if ($(iframe).contents().text().indexOf('window.asm_obaid') > -1) {
                $(iframe).contents().find('head').append(getCssLink());
            } else {
                var slideNumber = parentContainer.data('slide');
                var indicator = $('.stage-articles.home .carousel-indicators li[data-slide-to=' + slideNumber + ']');
                if (indicator.length > 0) {
                    $(indicator).remove();
                }
                parentContainer.remove();
            }
        });
        iframe.appendTo(this);
    })
}

//Tracking clicks in GoogleAnalytics
function trackOutboundLink(e, url) {
    e.preventDefault();
    window.open(url, '_blank');

    gtag('event', 'click', {
        'event_category': 'outbound',
        'event_label': url,
        'transport_type': 'beacon'

    });

}


function checkNewsletter() {
    var content = $('.newsletter-feed-current .replace');
    if (content.length > 0) {
        var url = content.data('url');
        $(content).load(url);
    }
}



