$(document).ready(function () {

  // $("a:contains('unique')").each(function() {
  //   var current = $(this);
  //   var elements = current.text().trim().split(";");

  //   var lastElement = elements[elements.length-1].trim();
  //   var fixedText = lastElement.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");

  //   $(this).text(fixedText);
  // });

  if ($(".footer:contains('unique-count')").length > 0){
    checkText();
  }
});

function checkText(){
  var text = $('.footer').text();
  var startPos = text.indexOf('[%url:unique-count;') + 1;
  var endPos = text.indexOf(']',startPos);
  var textToGet = text.substring(startPos,endPos)
  var elements = textToGet.split(";");

  var url = elements[1].trim().replaceAll("'", "");
  var lastElement = elements[elements.length-1].trim();
  var fixedText = lastElement.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
  var newHtml = $('.footer').html().replace("[" + textToGet + "]", '<a href="' + url + '">' + fixedText + '</a>');
  $('.footer').html(newHtml);

  if ($(".footer:contains('unique-count')").length > 0){
    checkText();
  }
}
