var selector = "form.form-register";
var popoverSelector = ".webui-popover-password-popover";

function initPopoverValidation() {
    $(selector + ' .password').webuiPopover({ url: '#validationData', placement: 'auto-right', style: 'password-popover', trigger: 'manual' });
    $(selector + " button[type=button]").prop("disabled", true);
}

$(document).ready(function() {

    initPopoverValidation();
    initDatePickers();
    listenFachgebietOptions();
    showFachgebietOptions();
    addFachgebietTooltip();

    checkFachgebeitSelect();

    $(document).on('click', '.registration button.submitRegisterFormTrigger', function () {
        $('.registration form button.submitRegisterForm').click();
        $("button[type=button].submitRegisterFormTrigger").prop("disabled", true);
    });

    $(document).on('change', '.registration form select.land', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('.registration form input.registration_btn').click();
    });

    $(document).on('change', '.registration form select.beruf', function (e) {
        e.preventDefault();
        e.stopPropagation();
        checkFachgebeitSelect();
    });

    $(document).on('hidden.bs.dropdown', '.registration .fachgebiet.select-wrapper.dropdown', function (e) {
        $('.registration .select-wrapper.fachgebiet').next().removeClass('focused');

        var selected = $('.registration .fachgebiet option:selected:enabled');
        var newValue = '';
        $(selected).each(function () {
            newValue += $(this).val() + ',';
        });
        newValue = newValue.substring(0, newValue.length - 1);
        $('.registration form input.registration_fachgebiet_hidden').val(newValue);
    });

    $(document).on('blur', '.registration .fachgebietTextInput input', function () {
        newValue = $('.fachgebietTextInput input').val();
        $('.registration form input.registration_fachgebiet_hidden').val(newValue);
    });

    $(document).on('click', '.registration .fachgebiet .custom_container', function () {
        $('.registration .select-wrapper.fachgebiet input.dropdown-toggle').click();
      });

    $(document).on('focusin', selector + ' .password', function(e) {
        $(selector + ' .password').webuiPopover('show');
    });

    $(document).on('focusout', selector + ' .password', function(e) {
        $(selector + ' .password').webuiPopover('hide');
    });

    $(document).on('keyup', selector + ' .password', function(e) {

        var pass = $(selector + ' .password').val();
        var username = $(selector + ' .username').val();
        var oldpassword = $(selector + ' .oldpassword').val();

        var validAll = true;

        cleanIcons('size');
        if (pass.length >= 8) {
            setValid('size');
        } else {
            setFail('size');
            validAll = false;
        }

        var validChars = 0;
        var uppercase = new RegExp('.*[A-Z]{1,}.*');
        if (uppercase.test(pass)) {
            validChars++;
        }

        var lowercase = new RegExp('.*[a-z]{1,}.*');
        if (lowercase.test(pass)) {
            validChars++;
        }

        var digits = new RegExp('.*[0-9]{1,}.*');
        if (digits.test(pass)) {
            validChars++;
        }

        var specials = new RegExp('.*[~!@#$%^&*_\\-+=`|(){}[\\]:;"\'<>,.?/ ]{1,}.*');
        if (specials.test(pass)) {
            validChars++;
        }

        cleanIcons('chars');
        if (validChars >= 2) {
            setValid('chars');
        } else {
            setFail('chars');
            validAll = false;
        }

        cleanIcons('username');
        var index = username.indexOf("@");
        var subUsername = "";

        if (index != -1) {
            subUsername = username.substring(0, index);
        }

        if (pass == '' || (pass != '' && pass.toLowerCase().indexOf(subUsername.toLowerCase()) != -1)) {
            setFail('username');
            validAll = false;
        } else {
            setValid('username');
        }

        cleanIcons('oldpassword');
        if (pass == '' || (pass != '' && oldpassword !== undefined && oldpassword != '' && pass.toLowerCase().indexOf(oldpassword.toLowerCase()) != -1)) {
            setFail('oldpassword');
            validAll = false;
        } else {
            setValid('oldpassword');
        }

        var quality = $(popoverSelector + ' .quality');
        quality.html('');
        quality.removeClass();
        quality.addClass('quality');

        cleanIcons('warning');
        if (pass != '') {
            /*result = zxcvbn(pass);
            quality.addClass('quality' + result.score);
            var qualityLabel;
            switch (result.score) {
                case 0:
                    qualityLabel = 'Niedrig';
                    break;
                case 1:
                    qualityLabel = 'Niedrig-Mittel';
                    break;
                case 2:
                    qualityLabel = 'Mittel';
                    break;
                case 3:
                    qualityLabel = 'Mittel-Hoch';
                    break;
                case 4:
                    qualityLabel = 'Hoch';
                    break;
            }
            $(popoverSelector + ' .quality').html(qualityLabel);

            if (result.feedback != null && result.feedback.warning != null && result.feedback.warning != '') {
                setFail('warning');
                validAll = false;
            } else {
                setValid('warning');
            }*/
        } else {
            setFail('warning');
        }

        if (validAll) {
            $("button[type=button].submitRegisterFormTrigger").prop("disabled", false);
        } else {
            $("button[type=button].submitRegisterFormTrigger").prop("disabled", true);
        }
    });

});

function listenFachgebietOptions() {
    var fachgebietList = $('.registration .fachgebiet ul');
    fachgebietList.find('li:not(.optgroup)').each(function (i) {
        $(this).click(function (e) {
            var selected = $('.registration .fachgebiet option:enabled:selected');
            if (selected.length > 3) {
                $(this).click();
            }
            showFachgebietOptions();
            addFachgebietTooltip();
        });
    });
}

function addFachgebietTooltip() {
    var selected = $('.registration .fachgebiet option:enabled:selected');
    var fachgebietOptions = $('.fachgebiet select option');
    var fachgebietLi = $('.fachgebiet li');
    fachgebietLi.each(function (i) {
        if (!$(this).hasClass('disable')){
            var tooltip = fachgebietOptions.eq(i).text();
            if (selected.length >= 3){
                tooltip = $('.registration .fachgebiet_tooltip_text').data('text');
            }
            $(this).attr('title', tooltip);
            $(this).attr('data-original-title', tooltip);
            $(this).tooltip({ 'title': tooltip });
        }
    });
}

function showFachgebietOptions() {
    var parent = $('.registration .fachgebietSelected .fachgebiet_selected');
    var selected = $('.registration .fachgebiet option:enabled:selected');
    if (selected.length > 0) {
        parent.show();
        parent.find('ul').remove();
        parent.append('<ul class="fachgebiet_selected_list"></ul>');
        var list = parent.find('ul');
        selected.each(function (index) {
            var option = $(this).text();
            list.append('<li>' + option + '</li>');
        });

        var fachgebietInput = $('.registration .fachgebiet input.select-dropdown');
        $('.registration .fachgebiet').find('div.custom_container').remove();

        if (selected.length > 1) {
            var content = $(selected[0]).text();
            if (content.length > 20) {
                content = content.substring(0, 19) + '...';
            }
            var more = selected.length - 1;
            fachgebietInput.val('');
            fachgebietInput.hide();
            fachgebietInput.after('<div class="custom_container"><span class="custom_label">' + content + '</span><span class="custom_label more">+' + more + '</span></div>');
        } else {
            fachgebietInput.show();
        }
    } else {
        parent.hide();
    }
}

function checkFachgebeitSelect() {

    if (isArztBeruf()) {
        $('.registration .fachgebietWrapper').css('display', 'block');
        $('.registration .fachgebietSelected').css('display', 'block');
        $('.registration .oakWrapper').css('display', 'block');
    } else {
        $('.registration .fachgebietWrapper').css('display', 'none');
        $('.registration .fachgebietSelected').css('display', 'none');
        $('.registration .oakWrapper').css('display', 'none');
        $('.registration form input.registration_fachgebiet_hidden').val('');
    }
    changeCustomChanges();
}

function changeCustomChanges() {

    if (!isArztBeruf() && isMainLand()) {
        $('.registration .verwendungWrapper').css('display', 'block');
    } else {
        $('.registration .verwendungWrapper').css('display', 'none');
    }

    if (isArztBeruf() && !isMainLand()) {
        $('.registration .other_lands_arzt').css('display', 'block');
    } else {
        $('.registration .other_lands_arzt').css('display', 'none');
    }

    if (isAndereBeruf() && !isMainLand()) {
        $('.registration .other_lands_andere').css('display', 'block');
    } else {
        $('.registration .other_lands_andere').css('display', 'none');
    }

    if (isArztBeruf() && isSelectedLand('7')) {
        $('.registration .abfrageWrapper').css('display', 'block');
    } else {
        $('.registration .abfrageWrapper').css('display', 'none');
    }
}

function isArztBeruf() {
    var beruf = $('.registration .beruf input').val();
    if (beruf == null) {
        beruf = $('.registration select.beruf').val();
    }
    return beruf != null && beruf.toLowerCase() === 'arzt';
}

function isAndereBeruf() {
    var beruf = $('.registration .beruf input').val();
    if (beruf == null) {
        beruf = $('.registration select.beruf').val();
    }
    return beruf != null && beruf.toLowerCase().startsWith('andere');
}

function isMainLand() {
    var land = $('.registration form select.land').val();
    return land != null && (land == '1' || land == '2' || land == '7');
}

function isSelectedLand(selectedLand) {
    var land = $('.registration form select.land').val();
    return land != null && land == selectedLand;
}

function initDatePickers() {
    $('.form-register #birthday').datepicker({ changeYear: true, yearRange: '1930:c', dateFormat: "yy-mm-dd" });
    $('.form-account #birthday').datepicker({ changeYear: true, yearRange: '1930:c', dateFormat: "yy-mm-dd" });
  }

//POPOVER
function cleanIcons(classSelector) {
    $(popoverSelector + ' i.' + classSelector).removeClass('fa-times');
    $(popoverSelector + ' i.' + classSelector).removeClass('fa-check');
}

function setValid(classSelector) {
    $(popoverSelector + ' i.' + classSelector).addClass('fa-check');
    $('.passwordValidation .' + classSelector).addClass('hidden');
}

function setFail(classSelector) {
    $(popoverSelector + ' i.' + classSelector).addClass('fa-times');
    $('.passwordValidation .' + classSelector).removeClass('hidden');
}

//DOCUMENT READY
// $('.form-register #birthday').datepicker({ changeYear: true, yearRange: '1930:c', dateFormat: "yy-mm-dd" });

// $(document).on('change', '.registration select.beruf', function() {
//     checkBerufSelect();
// });

// $(document).on('click', '.registration button.submitRegisterFormTrigger', function() {
//     $('.registration form button.submitRegisterForm').click();
// });

// if ($('.registration select.beruf').length > 0) {
//     checkBerufSelect();
// }

// $(document).on('click', '.registration .radio.fachbereiche input[type=radio]', function(event) {

//     var actual = $('.registration form.form-register input.fachbereiche.values').val();
//     if (actual.indexOf($(this).val()) >= 0) {
//         $(this).prop('checked', false);
//     }

//     var values = "";
//     $('.registration .radio.fachbereiche input[type=radio]:checked').each(function() {
//         values += $(this).val() + ",";
//     });
//     $('.registration form.form-register input.fachbereiche.values').val(values);
// });

// function checkBerufSelect() {
//     var beruf = $('.registration select.beruf').val();
//     if (beruf == 'arzt') {
//         //$('.registration form div.noArztOption').hide();
//         //$('.registration form .noArztOption').val('');
//         $('.registration form div.arztOption').show();
//     } else {
//         $('.registration form div.arztOption').hide();
//         $('.registration form .arztOption').val('');
//         //$('.registration form div.noArztOption').show();
//     }
// }